@import '../app/scss/index.scss';

.orders {
  margin: auto;
  max-width: 520px;
  display: grid;

  @include responsive($breakpoint-tablet) {
    max-width: 640px;
  }

  @include responsive($breakpoint-mobile) {
    max-width: 345px;
  }

  &_empty-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    max-width: inherit;
    padding: 0 30px;
  }

  //ordered/closed status with different styles on this page
  .wrapper-status.ordered,
  .wrapper-status.closed {
    color: $processingFontColor;
    background-color: $processingColor;
  }

  &_heading {
    font-weight: bold;
    font-size: 24px;
    margin: 0;
    padding-bottom: 15px;
  }

  &_card {
    display: grid;
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 4px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    grid-template-areas:
      'number status'
      'name date';

    &--number {
      grid-area: number;
      margin-bottom: 0;
    }

    &--status {
      grid-area: status;
      justify-self: end;
    }

    &--name {
      grid-area: name;
      color: $lightGreen;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 0;
      line-height: 1.2;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      white-space: break-spaces;
      overflow: hidden;
    }

    &--date {
      grid-area: date;
      margin-bottom: 0;
      justify-self: end;
    }
  }

  &_empty-icon {
    justify-self: center;
    padding-top: 30px;
  }

  &_empty-title {
    justify-self: center;
    margin-top: 10px;
    font-weight: bold;
    color: $darkGrey;
    font-size: 16px;
  }
}
