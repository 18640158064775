@import './breakpoints.scss';

/* Function to get responsive styles */
/* Example: `@include responsive($breakpoint-mobile) { *your styles here* }` */
@mixin responsive($media) {
  @if $media == $breakpoint-small-desktop {
    @media only screen and (max-width: $breakpoint-small-desktop) {
      @content;
    }
  } @else if $media == $breakpoint-tablet {
    @media only screen and (max-width: $breakpoint-tablet) {
      @content;
    }
  } @else if $media == $breakpoint-mobile {
    @media only screen and (max-width: $breakpoint-mobile) {
      @content;
    }
  } @else if $media == $breakpoint-small-mobile {
    @media only screen and (max-width: $breakpoint-small-mobile) {
      @content;
    }
  }
}
