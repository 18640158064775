/* Primary colors */
$green: #1cb57a;
$lightGreen: #20e3b2;
$darkGrey: #303030;
$white: #ffffff;
$black: #000000;
$grey: #d6d7da;
$lightGrey: #f5f5f5;

$darkFontColor: #333945;
$redFontColor: #f66d64;
$processingFontColor: #f7cb90;
$navLinkFontColor: #5c616a;
$navActiveLinkFontColor: #20e3b2;

$cardDark: #353c4c;
$cardLightGreen: #edf8f7;

$cardLabelGreen: #51c1b6;
$cardLabelLightYellow: #fff0d1;

$greenOrdersCard: rgba(29, 180, 112, 0.1);
$progressStatus: #6de2be;
$secondaryGreen: #1db47a;

$secondaryLightGreen: #d2f9f0;

$overdueColor: #fde2e0;
$processingColor: #fdf5e9;
