@import '../app/scss/index.scss';

@media screen and (min-width: 1024px) {
  #update-delivery-address {
    width: 580px;
  }
}

#update-delivery-address {
  margin: 20px auto;
  padding: 30px 15px;

  position: relative;

  .ant-form-item-explain.ant-form-item-explain-error {
    position: absolute;
    width: 100%;
    right: 0;
    bottom: 0;

    [role='alert'] {
      position: absolute;
      right: 0;
      width: 100%;
    }
  }

  .row {
    position: relative;
  }

  .ant-form-item-has-error {
    margin-bottom: 30px;
  }

  h1 {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 15px;
  }

  .align-center {
    text-align: center;
  }

  .submit-credentials {
    margin-top: 15px;
    border-radius: 30px;
    background-color: $lightGreen;
    color: $darkFontColor;
    display: inline-block;
    width: auto;
    padding: 20px;
    min-width: 345px;
  }

  .ant-form-item-explain-error.ant-show-help-enter.ant-show-help-enter-active.ant-show-help,
  .ant-form-item-explain-success.ant-show-help-leave.ant-show-help-leave-active.ant-show-help {
    &.ant-form-item-explain > div {
      font-size: 0;
    }
  }

  div.ant-col {
    position: static;
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: top;

    .half {
      width: calc(50% - 4px);
    }

    .bigger {
      width: calc(75% - 4px);
    }

    .smaller {
      width: calc(25% - 4px);
    }
  }

  a {
    color: #20e3b2;
    font-weight: bold;
  }
}
