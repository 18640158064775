@import '../app/scss/index.scss';

.MySpring {
  .product-img {
    background-image: url('https://images.ctfassets.net/ff8p9jfqoa7x/NapOwVayhi9JIuNCXMgsM/b198e31f1826e689126df49f2ea82011/Group_56.png');
  }
}

// TODO: check if this is still needed? -> the title says pause, even though it's a product image
.GoSpring {
  .product-img {
    background-image: url('https://images.ctfassets.net/ff8p9jfqoa7x/3w44DUi23WMLZ1PnMidhPP/5c71bb1da93aee24ced3f20cf823897c/gospring-pause.png');
  }
}

.treatment {
  max-width: 700px;
  margin: auto;
  display: grid;
  padding-top: 30px;
  font-size: 14px;

  @include responsive($breakpoint-mobile) {
    padding-top: 15px;
  }

  &_modal--content {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }

  &_block {
    padding: 0 90px;

    @include responsive($breakpoint-tablet) {
      padding: 0px 60px;
    }

    @include responsive($breakpoint-mobile) {
      padding: 0 15px;
    }
  }

  &_heading {
    display: grid;
    grid-template-areas:
      'subheading status'
      'title title';
    border-bottom: 1px solid $grey;
    margin-bottom: 16px;

    &--subheading {
      grid-area: subheading;
      line-height: 1.2;
      font-size: 16px;
      font-weight: 600;
    }

    &--status {
      grid-area: status;
      justify-self: end;
      height: fit-content;
    }

    &--title {
      grid-area: title;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.2;
      color: $lightGreen;
      margin-bottom: 8px;
    }
  }

  &_info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-size: 14px;

    &--product {
      &_name {
        margin-bottom: 6px;
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      &_icon {
        margin-right: 6px;
        vertical-align: middle;
      }

      .tablets {
        font-weight: 400;
        background-image: url('https://images.ctfassets.net/ff8p9jfqoa7x/1CYLZTANudp4bUGpfyCSTr/2dcabe58f584f52b8b8b21965e2dccfd/tablets.svg');
        background-repeat: no-repeat;
        padding-left: 25px;
        line-height: 20px;
      }
    }

    &--title {
      font-size: 14px;
      padding-top: 15px;
      margin-bottom: 0;
    }

    &--data {
      font-weight: bold;
      margin-bottom: 0;
    }
  }

  &_options {
    border-top: 1px solid $grey;
    display: grid;
    margin-top: 30px;
    justify-self: center;
    width: 100%;

    &--text {
      margin-top: 20px;
      font-size: 20px;
      font-weight: 600;
    }

    &--btn {
      justify-content: center;
      align-items: center;
      justify-self: center;
      height: 60px;
      max-width: 345px;
      width: 100%;
      margin-top: 15px;
    }

    &--btn.ant-btn {
      color: $darkGrey;
      background: white;
      height: 60px;
      display: flex;
      font-weight: bold;
      border: 3px solid $lightGreen;
      border-radius: 30px;
      font-size: 20px;

      &[disabled] {
        color: #ffffff;
        border-color: #c4c4c4;
        background: #c4c4c4;
        text-shadow: none;
        box-shadow: none;
      }
    }
  }

  &_orders {
    margin-top: 30px;
    border-top: 1px solid $grey;

    .title {
      margin-top: 20px;
      font-size: 20px;
      font-weight: 600;
    }

    .orders-container {
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
      padding: 0 15px;
      border-radius: 4px;
    }

    .expand-arrow-container {
      height: 45px;
      border-top: 1px solid #dcdcdc;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: #fcfcfc;
      }

      .arrow {
        transition: transform 0.2s ease-in-out;

        &.expanded {
          transform: rotate(180deg);
        }
      }
    }

    .orders_card {
      display: grid;
      padding: 15px 0;
      box-shadow: none;
      margin: 0;
      border-top: 1px solid #dcdcdc;
      border-radius: 0;
      cursor: pointer;
      grid-template-areas:
        'number status'
        'name date';

      &:first-child {
        border: none;
      }

      &:hover {
        background-color: #fcfcfc;
      }

      &--number {
        grid-area: number;
        margin-bottom: 0;
      }

      &--status {
        grid-area: status;
        justify-self: end;
      }

      &--name {
        grid-area: name;
        color: $darkFontColor;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 0;
        line-height: 1.2;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: break-spaces;
        overflow: hidden;
      }

      &--date {
        grid-area: date;
        margin-bottom: 0;
        justify-self: end;
      }
    }
  }

  .wrapper {
    display: inline-block;
  }

  .item-description {
    display: none;
  }

  .item {
    min-width: 350px;

    &.treatment {
      grid-area: treatment;
      box-shadow: $main-box-shadow;
      padding: 6px 20px;

      .row {
        display: flex;
        justify-content: space-between;
        margin-top: 0;
        margin-bottom: 5px;
      }

      .brand {
        margin-top: 15px;
      }

      .status {
        color: white;
        background-color: $lightGreen;
        border-radius: 12.5px;
        padding: 6px 16px;
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;
        height: 29px;
      }

      .title {
        color: $lightGreen;
        font-size: 20px;
        line-height: 16px;
      }

      .date-value,
      .description {
        font-size: 13px;
        margin: 0;
        color: $white;
      }
    }
  }
}

.page-wrapper.treatmentPause footer {
  display: none;
}

.advanced-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  .primitive-btn {
    cursor: pointer;
    width: 100%;
    margin-top: 0;
  }
}

.treatment-flow-container {
  @include centered-content;

  .details-container {
    padding: 40px 10px 20px 10px;
  }

  .details {
    width: 375px;
    flex-direction: column;
    display: flex;

    .product-img {
      background-color: #c4c4c4;
      height: 180px;
      width: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .status {
      font-size: 16px;
      margin-bottom: 15px;
    }

    .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 20px;
      &.duration {
        text-align: center;
      }
    }

    .descriptive-info {
      font-size: 14px;
      margin-top: 19px;

      &.colapsed {
        margin-top: 0;
      }
    }

    .important {
      color: #ff6666;
      margin-top: 24px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
    }

    .important-info {
      font-size: 12px;
      font-style: italic;
    }

    .submit {
      color: #303030;
      background: #20e3b2;
      display: flex;
      font-weight: bold;
      border: none;
      border-radius: 30px;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      font-size: 20px;
      padding: 15px;
      height: auto;
      white-space: normal;
      width: 100%;

      &[disabled] {
        color: rgba(0, 0, 0, 0.25);
        border-color: #d9d9d9;
        background: $lightGrey;
        text-shadow: none;
        box-shadow: none;
      }
    }

    .option {
      font-size: 18px;
      border: 0.3px solid #9ea0a3;
      border-radius: 4px;
      padding: 15px;
      font-weight: 600;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;
      .option-selected {
        display: none;
        font-size: 14px;
        font-weight: 400;
      }

      &.selected {
        border: 2px solid #20e3b2;
        padding: 13px;
        .option-selected {
          display: inline-block;
        }
      }

      &:hover {
        background-color: #f3f5f4;
      }
    }

    .treatment {
      padding: 0;
      width: 100%;
    }

    .treatment_block {
      padding: 0;
    }

    .next-delivery {
      font-size: 16px;
      margin-top: 20px;
    }

    .delivery-value {
      color: $lightGreen;
      font-size: 20px;
      font-weight: 600;
    }
  }
}
