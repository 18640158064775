.App {
  text-align: center;
}

.ant-btn > .ant-btn-loading-icon {
  padding-right: 8px;
}
#launcher {
  /*Have to use !important here to override inline widget styles */
  margin-bottom: -100% !important;
}

#launcher.visible {
  margin-bottom: 80px !important;
}
