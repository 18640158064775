@import '../../app/scss/index.scss';

.survey-container {
  color: $darkFontColor;

  h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
  }

  .option {
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
    cursor: pointer;

    // overwrite radio styles
    .ant-radio-inner {
      border-color: $darkGrey;
    }
    .ant-radio-wrapper:hover .ant-radio-inner {
      border-color: $lightGreen;
    }
    .ant-radio-checked .ant-radio-inner {
      background-color: $darkGrey;
      border-color: $darkGrey;
    }
    .ant-radio-inner::after {
      background-color: $lightGreen;
    }
  }

  .submit-credentials {
    &.button.ant-btn.ant-btn-primary {
      background-color: rgba(255, 103, 103, 0.16);
      color: $redFontColor;
      border: 1px solid $redFontColor;
      margin-bottom: 0;
    }

    &:disabled {
      background-color: $lightGrey !important;
      color: $grey !important;
      border: 1px solid $grey !important;
      pointer-events: none;
    }
  }

  .button-wrapper {
    cursor: pointer;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .submit-credentials, .button-wrapper {
    border-radius: 30px;
  }

  .reason-field {
    background-color: $lightGrey;
    width: 100%;
    height: 120px;
    margin-top: 10px;
    border: 1px solid $grey;
    border-radius: 8px;
    box-shadow: 4px 4px 14px 0px $lightGrey;
    padding: 10px;
    outline: none;
  }
}
