@import '../app/scss/index.scss';

.treatmentsOverview {
  max-width: 520px;
  margin: auto;
  padding-top: 30px;

  @include responsive($breakpoint-tablet) {
    max-width: 640px;
  }

  @include responsive($breakpoint-mobile) {
    max-width: 345px;
  }

  &_heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  &_sub-heading {
    font-size: 16px;
    margin-bottom: 30px;
  }

  &_card {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    display: grid;
    grid-template-areas:
      'title status'
      'name name'
      'type type'
      'delivery delivery';

    &--title {
      grid-area: title;
      font-size: 14px;
      line-height: 17px;
    }

    &--name {
      grid-area: name;
      font-size: 24px;
      font-weight: bold;
      line-height: 29px;
      color: $lightGreen;
      margin-bottom: 15px;
    }

    &--type {
      grid-area: type;
      font-size: 12px;
      line-height: 14px;
      font-weight: bold;
      display: flex;
      align-items: center;
      margin-bottom: 6px;
    }

    &--delivery {
      grid-area: delivery;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      align-items: center;
    }

    &--icon {
      margin-right: 4px;
    }
  }

  .header {
    font-size: 24px;
    padding: 0 15px;
    font-weight: 600;
  }

  .description {
    padding: 0 15px;
  }

  .links-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .link {
    display: block;
    background: #20e3b2;
    border-radius: 30px;
    width: 345px;
    text-align: center;
    color: $darkFontColor;
    font-size: 18px;
    font-weight: 600;
    padding: 16px 0;
    margin-top: 15px;
  }
}
