@import '../../app/scss/index.scss';

.advanced-options-container {
  color: $darkFontColor;
  
  h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
  }

  h4 {
    font-size: 16px;
    font-weight: 600;
  }

  a {
    color: $darkFontColor;
    text-decoration: underline;

    &:hover {
      color: $lightGreen;
    }
  }

  .centered-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;

    .primitive-btn {
      cursor: pointer;
      margin-top: 10px;
    }

    .submit-credentials {
      &.confirm.button.ant-btn.ant-btn-primary {
        background-color: rgba(255, 103, 103, 0.16);
        color: $redFontColor;
        border: 1px solid $redFontColor;
        margin-bottom: 0;
      }

      &.description-step {
        margin-top: 20px;
        margin-bottom: 10px;
      }

      &.pausing-step {
        margin-top: 24px;
        margin-bottom: 0;
      }

      &:disabled {
        background-color: $lightGrey !important;
        color: $grey !important;
        border: 1px solid $grey !important;
        pointer-events: none;
      }
    }

    .button-wrapper {
      cursor: pointer;
      width: 100%;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    .submit-credentials,
    .button-wrapper {
      border-radius: 30px;
    }
  }

  .support {
    margin-top: 120px;
  }

  .success-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 120px;

    h3,
    .redirect {
      margin-top: 40px;
    }

    h3 {
      margin-bottom: 8px;
    }

    .redirect-image {
      width: 90%;
      max-width: 300px;
    }
  }

  .pausing-title {
    margin: 4px 0;
  }

  .pausing-text {
    font-size: 16px;
  }

  .pausing-image {
    width: 56px;
    height: 56px;
  }

  .pausing-icon-text {
    display: flex;
    gap: 5px;
    margin-top: 8px;

    img {
      width: 20px;
      height: 20px;
    }

    p {
      font-size: 13px;
      margin-bottom: 0;
    }
  }

  // overwrite checkbox styles
  .ant-checkbox-inner,
  .ant-checkbox-checked::after {
    border-color: $darkGrey;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: $lightGreen;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $darkGrey;
    border-color: $darkGrey;
  }
}

.input-necessary .modal-content {
  color: $redFontColor;
  display: flex;

  p {
    font-size: 16px;
    line-height: 24px;
    margin-left: 8px;
    margin-bottom: 0;
  }
}

.pausing-modal {
  .ant-modal-body {
    padding: 20px;    
    display: flex;
  }

  .pausing-confirmation-title, .pausing-confirmation-description {
    color: $darkFontColor;
    font-family: "Avenir Next";
    font-style: normal;
    line-height: normal;
  }

  .pausing-confirmation-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  .pausing-confirmation-description {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 16px;
  }

  .ant-modal-content {
    border-radius: 8px;
    max-width: 345px;
    margin: auto;
    border: 1px solid rgba(0, 0, 0, 0.20);
    box-shadow: 4px 4px 14px 0px rgba(0, 0, 0, 0.10);
  }

  .submit-credentials {
    &.cancel {
      background-color: transparent;
      border: 1px solid $black;
      margin: 0  ;
    }
  }

  .pausing-confirmation-image {
    width: 48px;
    height: 48px;
    margin-bottom: 4px;
  }
}