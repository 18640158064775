@import '../app/scss/index.scss';

@media screen and (min-width: 1024px) {
  #update-email {
    width: 580px;
  }
}

#update-email {
  margin: 20px auto;
  padding: 30px 15px;
  position: relative;

  h1 {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
  }

  .align-center {
    text-align: center;
  }

  .submit-credentials {
    margin-top: 15px;
    border-radius: 30px;
    background-color: $lightGreen;
    color: $darkFontColor;
    display: inline-block;
    width: auto;
    padding: 20px 20px;
    min-width: 345px;
  }
}
