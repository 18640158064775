@import '../../scss/mixins.scss';
@import '../../scss/size';
@import '../../scss/colors';

.header--app {
  z-index: 11;
  position: fixed;
  width: 100%;
  background: #333945;
  top: 0;

  &.logout-header {
    display: none;
  }
}

.health-pass {
  color: #1db47a;
}

.logo {
  background: url('https://images.ctfassets.net/ff8p9jfqoa7x/3UCwHsLEkKcqLrmo32ht95/32424bc86a3550b57e1327611909d699/spring.png') no-repeat center;
  height: 30px;
  width: 95px;
  display: inline-block;
}

.MySpring {
  .logo {
    background: url('https://images.ctfassets.net/ff8p9jfqoa7x/6ivNr3sX0JWMPZDK1Yk1F5/7c013f6af43cd6f276ac7883f0d86d20/myspring.svg') no-repeat center;
  }
}

@media screen and (max-width: 1024px) {
  .header--app {
    &.updateEmail-header,
    &.update-billing-address-header,
    &.update-email-header,
    &.update-delivery-address-header,
    &.update-user-password-header {
      height: 86px;
      position: fixed;

      .back-btn {
        position: relative;
        z-index: 1;
        margin-top: 40px;
      }

      &:after {
        position: absolute;
        content: 'Einstellungen';
        color: white;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        width: 100%;
        display: block;
        bottom: 10px;
      }

      a {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .back-btn {
    display: none;
  }
  .header-list-item {
    display: flex;

    &.active {
      a {
        color: #25b47a;
      }

      a.settings {
        background: url('https://images.ctfassets.net/ff8p9jfqoa7x/2Qgyl0NwnBelBEyAero4S7/63d3399ee1e6843f23230f12a7dba8c9/settings-active.svg') no-repeat 0 0;
      }
    }

    a {
      display: inline-block;
      height: 24px;
      margin-right: 30px;
      color: white;
      font-size: 14px;
      text-decoration: underline;

      &.settings {
        background: url('https://images.ctfassets.net/ff8p9jfqoa7x/1UzJUIUlk0x7UvZI2CyI5j/4b82ae02a481d0e111faf814fcbdc5cf/settings.svg') no-repeat 0 0;
        width: 18px;
        height: 18px;
      }

      svg {
        height: 30px;
        display: block;
        margin: 0 auto;
      }

      &.dashboard rect {
        stroke: #303030;
      }

      &.orders path,
      &.treatments path {
        fill: #303030;
      }

      &.dashboard:hover rect {
        stroke: #25b47a;
      }

      &.orders:hover path,
      &.treatments:hover path {
        fill: #25b47a;
      }

      &:hover {
        color: #25b47a;
      }
    }
  }

  .header--content {
    display: flex;
    max-width: 1240px;
    flex-direction: row;
    border-bottom: none;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 9px 15px;
    margin: 0 auto;
  }

  .logout {
    background-image: url('https://images.ctfassets.net/ff8p9jfqoa7x/2qkBPgurNI4sYkbTQ1ZmCv/87cd581014e21ced6ce29d4c8429b928/logout-icon.png');
    width: 32px;
    height: 32px;
    font-size: 0;
    position: static;
    background-color: transparent;
  }

  .header--list {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 1024px) {
  .back-btn {
    width: 12px;
    height: 12px;
    display: inline-block;
    background-image: url('https://images.ctfassets.net/ff8p9jfqoa7x/BQs74C6reOcewtTbYs1B5/88971b22276a1091b3d3938e2fad66ac/back-icon.png');
    cursor: pointer;
  }

  ul.header--list {
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 0;

    li {
      display: flex;
    }
  }

  nav.header-navigation a.overview,
  nav.header-navigation a.treatments,
  nav.header-navigation a.orders {
    display: none;
  }

  nav.header-navigation a.settings {
    background: url('https://images.ctfassets.net/ff8p9jfqoa7x/1UzJUIUlk0x7UvZI2CyI5j/4b82ae02a481d0e111faf814fcbdc5cf/settings.svg') no-repeat 0 0;
    width: 18px;
    height: 18px;
  }

  .logout {
    border-radius: 13px;
    background: #eaeaea;
    padding: 4px 16px;
    color: #000000;
    font-size: 12px;
    position: absolute;
    bottom: 10px;
    right: 14px;
    height: 42px;
    line-height: 36px;
    font-weight: bold;
    width: auto;
  }

  div.header--content {
    width: 100%;
    border-bottom: none;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
  }
}
