@import '../app/scss/index.scss';

.underline {
  border-bottom: 1px solid $grey;
  padding-bottom: 5px;
}

.order-container {
  max-width: 700px;
  margin: auto;
  width: 100%;
  display: grid;
  padding-top: 30px;

  //ordered/closed status with different styles on this page
  .wrapper-status.ordered,
  .wrapper-status.closed {
    color: $processingFontColor;
    background-color: $processingColor;
  }

  .text-content {
    margin: 0;
    font-size: 14px;
    line-height: 1.2;
    align-self: center;
  }

  .dhl-link {
    color: #20e3b2;
    background-repeat: no-repeat;
    background-position-y: center;
    padding-left: 21px;
    margin-top: 10px;
    text-decoration: underline;
    background-image: url('https://images.ctfassets.net/ff8p9jfqoa7x/64rWOO2ud72wHqPTdp280M/bec5d6490961e0bc76065a130b0aa3f2/dhl-link.png');
  }

  .order {
    &_header {
      display: grid;
      grid-template-columns: 2fr 1fr;
      border-bottom: 1px solid $grey;
      padding: 0 15px 15px;

      &--status {
        grid-area: auto;
      }

      &--name {
        font-size: 24px;
        color: $lightGreen;
        font-weight: bold;
      }

      &--date {
        justify-self: end;
      }
    }

    &_prices {
      display: grid;
      grid-template-columns: 2fr 1fr;
      border-bottom: 1px solid $grey;
      padding: 15px;

      &--heading {
        grid-column-start: 1;
        grid-column-end: 3;
      }

      &--title {
        font-weight: bold;
        padding-top: 15px;

        .tablets {
          font-size: 12px;
          font-weight: 400;
        }
      }

      &--text {
        font-weight: normal;

        &-right {
          text-align: end;
          align-self: end;
        }
      }
    }

    &_contacts {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 10px;
      border-bottom: 1px solid $grey;
      padding: 15px;

      &--heading {
        padding-bottom: 15px;
      }

      &--bold-text {
        font-weight: bold;
      }
    }

    &_files {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-auto-flow: column;
      column-gap: 10px;
      border-bottom: 1px solid $grey;
      padding: 15px;

      &--heading {
        grid-column-start: 1;
        grid-column-end: 3;
        padding: 15px 0 0 15px;
      }

      a {
        text-decoration: underline;
        font-size: 12px;
        color: $lightGreen;
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        svg {
          margin-right: 8px;
        }
      }
    }

    &_reorder {
      &--text {
        text-align: center;
        font-style: italic;
        padding-top: 15px;
        margin-bottom: 0;
      }

      &--label_disabled {
        margin-top: 20px;
        text-align: center;
        padding: 0 10px;
        font-style: italic;
      }

      &--btn {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-self: center;
        height: 60px;
        max-width: 345px;
        width: 100%;
        font-weight: bold;
        border: none;
        border-radius: 30px;
      }

      &--btn.ant-btn:not([disabled]) {
        color: $darkGrey;
        margin-top: 30px;
        background: $lightGreen;
      }

      &--btn.ant-btn-primary[disabled] {
        background: $grey;
        margin-top: 15px;
      }
    }
  }
}
