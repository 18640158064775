@import './mixins.scss';
@import './colors.scss';

.MySpring {
  .pre-login-screen {
    .header {
      background-image: url('https://images.ctfassets.net/ff8p9jfqoa7x/RoDeMqebyQVjYl9840RtH/18bc8d054573b4e295056d6ea6b75d32/myspring-header-background-long.png');
    }
  }
}

.GoSpring {
  .pre-login-screen {
    .header {
      background-image: url('https://images.ctfassets.net/ff8p9jfqoa7x/6TssIafax8IGWV72i9Y1ll/240b61107c02d253f4a329231ee876da/spring-header-background-long.png');
    }
  }
}

.pre-login-screen {
  .header {
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
    transition: height 0.2s ease;
    background-image: url('https://images.ctfassets.net/ff8p9jfqoa7x/6TssIafax8IGWV72i9Y1ll/240b61107c02d253f4a329231ee876da/spring-header-background-long.png');
    height: 390px;

    background-repeat: no-repeat;
    background-size: cover;

    &.short {
      position: relative;
      padding: 10px 20px;
      background-color: $darkFontColor;
      flex-direction: row;
      justify-content: space-between;
      height: 50px;
      background-image: none;
    }
  }

  .title {
    margin-top: auto;
    font-size: 24px;
    color: $white;
  }

  .error-message {
    @include box-message;
    background-color: $redFontColor;
  }

  .success-message {
    @include box-message;
    background-color: $lightGreen;
  }

  .description {
    width: 340px;
    text-align: center;
    color: $white;
    line-height: 17px;
    font-size: 14px;
    margin-top: 10px;
    font-weight: 400;
  }

  .requirements {
    margin-top: 20px;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 580px;
    margin: 0 auto;

    .title {
      color: #000;
      font-size: 24px;
      text-align: center;
      font-weight: 600;
      line-height: 29px;
      margin-top: 60px;
    }

    .description {
      line-height: 17px;
      text-align: center;
      margin-top: 15px;
      color: $black;
    }

    form {
      display: flex;
      flex-direction: column;
    }

    .login-problems {
      @include centered-content;
      margin-top: 20px;
    }

    .link-btn,
    button.submit-credentials {
      margin: 0 auto;
      background-color: $lightGreen;
      outline: none;
      min-width: 345px;
      border: none;
      margin-bottom: 10px;
      font-weight: 600;
      cursor: pointer;
      color: #333945;
      border-radius: 30px;
      margin-top: 20px;
      display: inline-block;
      text-align: center;
      padding: 20px;
      font-size: 18px;
      line-height: 20px;
    }
    .link-container {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
    }
    a.link-btn {
      margin-top: 0;
      min-width: 100%;
    }
  }

  a {
    color: #000;
  }

  .ant-form {
    .field-key {
      margin-bottom: 5px;
      margin-top: 13px;
      font-size: 18px;
      line-height: 22px;
    }

    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .ant-input-affix-wrapper {
    height: 60px;

    .ant-input-prefix {
      margin-right: 10px;
    }
  }

  .ant-input-affix-wrapper > input.ant-input {
    height: 50px;
    background-color: white;

    &:focus,
    &:active {
      background-color: white;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}

@media (max-width: 900px) {
  .MySpring {
    .pre-login-screen {
      .header {
        background-image: url('https://images.ctfassets.net/ff8p9jfqoa7x/3ch6Xhf9wy5TU5heDHQtWM/e51b21b53c8ceff1ae9c45c6ae83735e/background.png');
      }
    }
  }

  .pre-login-screen {
    .header {
      background-image: url('https://images.ctfassets.net/ff8p9jfqoa7x/3ch6Xhf9wy5TU5heDHQtWM/e51b21b53c8ceff1ae9c45c6ae83735e/background.png');
      height: 540px;
    }
  }
}

@media (max-width: 600px) {
  .MySpring {
    .pre-login-screen {
      .header {
        background-image: url('https://images.ctfassets.net/ff8p9jfqoa7x/RoDeMqebyQVjYl9840RtH/18bc8d054573b4e295056d6ea6b75d32/myspring-header-background-long.png');
      }
    }
  }

  .pre-login-screen {
    .header {
      background-image: url('https://images.ctfassets.net/ff8p9jfqoa7x/RoDeMqebyQVjYl9840RtH/18bc8d054573b4e295056d6ea6b75d32/myspring-header-background-long.png');
      height: 300px;
    }
  }

  .pre-login-screen {
    .form-container {
      width: 100%;
      padding: 0 20px;
    }

    button.submit-credentials.ant-btn {
      width: 100%;
      min-width: initial;
    }

    .back-button {
      width: 12px;
      height: 12px;
      background-image: url('https://images.ctfassets.net/ff8p9jfqoa7x/BQs74C6reOcewtTbYs1B5/88971b22276a1091b3d3938e2fad66ac/back-icon.png');
    }
  }
}
