@import '../app/scss/index.scss';

#update-user-password {
  margin: 20px auto;
  padding: 30px 15px;
  max-width: 580px;
  position: relative;

  .section-title {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 1024px) {
  #update-user-password {
    max-width: 580px;
  }
}
