@import '../../app/scss/index.scss';

.treatment-pausing {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  gap: 12px;
  background-color: $lightGrey;
  padding: 12px;
  border-radius: 8px;

  .left .date {
    background-color: $black;
  }

  .right .date {
    background-color: #317fe3;
  }

  .arrow {
    font-size: 20px;
  }
}

.next-pausing-available {
  background-color: $lightGrey;
  padding: 12px;
  border-radius: 8px;

  .date-box-next-pausing {
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;

    .text {
      color: #818A9C;
      margin: 0;
    }

    .date {
      color: $black;
      background-color: #e5e5e5;
    }
  }
}

.date-box {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .text {
    font-size: 14px;
    line-height: normal;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .date {
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    color: $white;
    padding: 4px 8px;
    border-radius: 8px;
    width: min-content;
  }
}
