@import '../app/scss/colors';

.MySpring {
  .image-container {
    &.spring-tile {
      background-image: url('https://images.ctfassets.net/ff8p9jfqoa7x/5tW0CQbMqX5nIogLuQ5U8G/be7dda2dd49733caf8addf212bc2308d/tile-myspring-1.png?h=250');
    }

    &.treatments-tile {
      background-image: url('https://images.ctfassets.net/ff8p9jfqoa7x/3WhBK0WrZquSjXfwbCKOzL/8381e73b54ce66da00554c3816e4934a/tile-myspring-2.png?h=250');
    }

    &.orders-tile {
      background-image: url('https://images.ctfassets.net/ff8p9jfqoa7x/1Xf2R724YbjSYWflnblIXu/985ea2151c3b04bce741f87557c96686/tile-myspring-3.png?h=250');
    }
  }
}

.GoSpring {
  .image-container {
    &.spring-tile {
      background-image: url('https://images.ctfassets.net/ff8p9jfqoa7x/2YBxcwjL1DuoR7JVfkVdDj/69feb4c09d71a8624df4d36dcc88f644/tile-gospring-1.png?h=250');
    }

    &.treatments-tile {
      background-image: url('https://images.ctfassets.net/ff8p9jfqoa7x/1AqpzYw0rRKCAFGQ2bzzO9/71b84d283424781b05d718de9563b709/tile-gospring-2.png?h=250');
    }

    &.orders-tile {
      background-image: url('https://images.ctfassets.net/ff8p9jfqoa7x/6R5dIK285gCLOS1qnmd5nZ/0ce6790ee5455f8072a682ab815ffd66/tile-gospring-3.png?h=250');
    }
  }
}

.dashboard {
  width: 520px;
  margin: 0 auto;

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }

  .prime-orders {
    margin-top: 19px;

    .orders-list {
      list-style: none;
      border: 0.3px solid #333945;
      border-radius: 4px;
      margin: 0;

      & > div {
        padding-left: 15px;
        padding-right: 15px;
      }

      div.order-count {
        background: #333945;
        line-height: 12px;
        padding: 9px 15px;
        color: white;
        text-transform: uppercase;
        font-weight: bold;
        display: none;

        .counter {
          color: #20e3b2;
        }
      }

      @media screen and (max-width: 1024px) {
        div.order-count {
          display: block;
        }
      }
    }
  }

  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    margin-top: 30px;
  }

  .sub-header {
    font-size: 14px;
    line-height: 17px;
    margin-top: 30px;
    font-weight: normal;
  }

  .image-container {
    min-width: 250px;
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    flex: 1;
  }

  .tiles {
    .tile {
      color: black;
      display: flex;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      margin-top: 15px;
      justify-content: space-between;
      border-radius: 4px;
      height: 125px;

      &.prime {
        background: $lightGreen;

        .caption {
          color: white;
        }
      }

      &.no-prime {
        background: #333945;

        .caption {
          color: $lightGreen;
        }

        .details {
          color: white;
        }
      }

      .info {
        padding: 20px 15px;
        flex: 3;
      }

      h3.caption {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: $lightGreen;
        margin-bottom: 5px;
      }

      .details {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .dashboard {
    width: 345px;

    .image-container {
      min-width: 108px;
    }

    .tiles {
      .tile {
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        margin-top: 15px;

        .info {
          padding: 10px;
        }
      }
    }
  }
}
