@import '../../scss/index.scss';

.custom-confirm-modal div.ant-modal-content {
  button.ant-modal-close {
    position: fixed;
    top: 18px;
    left: 0;

    span {
      color: $white;
    }
  }
}

.custom-confirm-modal div.ant-modal-body {
  border-radius: 4px;

  .confirm-modal_button {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-ok {
      max-width: 315px;
      width: 100%;
      background-color: $lightGreen;
      color: $cardDark;
      padding: 20px 0 18px;
      text-align: center;
      height: 60px;
      font-weight: bold;
      border-radius: 30px;
      border: none;
    }

    &-cancel {
      border: none;
      color: $lightGreen;
      margin-top: 10px;

      span {
        text-decoration: underline;
      }
    }

    &_cancel-icon {
      position: absolute;
      top: 0;
    }
  }
}
