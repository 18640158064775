@mixin centered-content {
  display: flex;
  justify-content: center;
}

@mixin error-list {
  .list-title {
    text-decoration: underline;
  }

  ul {
    margin: 0;
    width: 100%;
    padding: 0 50px;
  }
}

@mixin box-message {
  border-radius: 4px;
  color: $white;
  font-weight: 600;
  font-size: 14px;
  padding: 18px 20px;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

@mixin credentials-error-message {
  background-color: #ffd7d7;
  font-size: 14px;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 15px;
  margin-bottom: 15px;
  margin-top: 60px;
  flex-direction: column;
  line-height: 16px;

  @include error-list;
}

@mixin email-message {
  display: block;
  color: #1cb57a;
  text-align: center;
  font-weight: 600;
  margin-bottom: 40px;
}

@mixin link-switcher {
  font-size: 18px;
  font-weight: 600;
  border-radius: 4px;
  display: flex;
  justify-content: center;

  .items {
    display: flex;
    width: 345px;
    border: 1px solid #20e3b2;

    span {
      text-decoration: none;
      text-transform: capitalize;
      padding: 21px 0;
      display: inline-block;
      color: black;
      flex: 1;
      text-align: center;
      cursor: pointer;

      &.selected {
        background: #a6f4e0;
      }
    }
  }
}
