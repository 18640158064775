@import '../../app/scss/index.scss';

.treatment-benefits {
  &.background {
    background-color: $secondaryLightGreen;
    padding: 20px 15px;
    border-radius: 8px; 

    .benefit {
      background-color: rgba(255, 255, 255, 0.4);
    }
  }

  .title {
    font-size: 20px;
    margin-bottom: 4px;
  }

  .benefit {
    background-color: $secondaryLightGreen;
    border-radius: 8px; 
    display: flex;
    align-items: center;
    padding: 8px 10px;
    margin-top: 8px;

    .benefit-image {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    .benefit-text {
      font-size: 14px;
    }

    p {
      margin: 0;
    }
  }
}
