
.primitive-btn{
    color: #333945;
    font-size: 16px;
    text-decoration: underline;
    background: transparent;
    text-align: start;
    border: none;
    margin-top: 13px;
    padding: 0;
  }