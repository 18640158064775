@import '../app/scss/index.scss';

@media screen and (min-width: 1024px) {
  #settings {
    width: 1240px;
  }
}

#settings {
  margin: 20px auto;
  padding: 30px 15px;
  position: relative;

  h1 {
    display: none;
  }
  @media screen and (min-width: 1024px) {
    h1 {
      display: block;
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 30px;
      margin-top: 10px;
    }
  }

  p.section-label {
    padding-top: 15px;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0;
  }

  .flex-block {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #d6d7da;
    padding: 15px 0;

    &.general {
      padding: 15px 0 0 0;
      border: 0;
    }

    & > div {
      width: 50%;
    }
  }

  .additional-block {
    border-bottom: 1px solid #d6d7da;
    padding-bottom: 15px;
  }

  .field-name {
    font-size: 12px;
    line-height: 14px;
  }
  .field-value {
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
  }

  .submit-credentials {
    margin-top: 15px;
    border-radius: 30px;
    background-color: $lightGreen;
    color: $darkFontColor;
    display: inline-block;
    width: auto;
    padding: 20px 103px;
  }

  .align-center {
    text-align: center;
  }

  a.logout-link {
    display: block;
    color: #20e3b2;
  }
}

@media screen and (max-width: 1024px) {
  #settings {
    margin-top: 0;
    padding-top: 10px;

    p.field-value {
      max-width: 100%;
      word-break: break-word;
    }

    .flex-block > div {
      width: 50%;
      padding-right: 5px;
    }
  }
}
