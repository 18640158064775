@import '../app/scss/index.scss';

@mixin custom-notification {
  min-height: 71px;

  .ant-notification-notice-with-icon .ant-notification-notice-message,
  .ant-notification-notice-with-icon .ant-notification-notice-description {
    padding-right: 10px;
    font-size: 14px;
    margin-left: 40px;
  }
}

div.ant-notification {
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  bottom: 0;
  margin-right: 0;
}

div.ant-notification-notice {
  padding: 10px;
  display: flex;
  font-weight: bold;
  max-width: 700px;
  width: 100%;

  @include responsive($breakpoint-tablet) {
    max-width: 580px;
  }

  @include responsive($breakpoint-mobile) {
    max-width: 345px;
  }
}

.custom-success-notification {
  @include custom-notification;

  .ant-notification-notice-content {
    border-left: 2px solid $lightGreen;
    padding: 5px;
  }
}

.custom-error-notification {
  @include custom-notification;

  .ant-notification-notice-content {
    padding: 5px;
    border-left: 2px solid $redFontColor;
  }
}

.ant-notification-notice-close {
  display: none;
}
