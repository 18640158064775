@import 'size';
@import './breakpoints.scss';
@import './functions.scss';
@import './colors.scss';
@import './constants.scss';
@import './mixins.scss';

@font-face {
  font-family: 'Avenir Next Lt Pro';
  src: local('Avenir Next Lt Pro'), url(../fonts/AvenirLTProLight.otf) format('truetype');
}

@font-face {
  font-family: 'Avenir Next Lt Pro';
  font-style: italic;
  src: local('Avenir Next Lt Pro'), url(../fonts/AvenirLTProLightItalic.otf) format('truetype');
}

@font-face {
  font-family: 'Avenir Next Lt Pro';
  font-weight: 500;
  src: local('Avenir Next Lt Pro'), url(../fonts/AvenirLTProMedium.otf) format('truetype');
}

@font-face {
  font-family: 'Avenir Next Lt Pro';
  font-style: italic;
  font-weight: 500;
  src: local('Avenir Next Lt Pro'), url(../fonts/AvenirLTProMediumItalic.otf) format('truetype');
}

@font-face {
  font-family: 'Avenir Next Lt Pro';
  font-weight: 700;
  src: local('Avenir Next Lt Pro'), url(../fonts/AvenirLTProHeavy.otf) format('truetype');
}

@font-face {
  font-family: 'Avenir Next Lt Pro';
  font-style: italic;
  font-weight: 700;
  src: local('Avenir Next Lt Pro'), url(../fonts/AvenirLTProHeavyItalic.otf) format('truetype');
}

* {
  box-sizing: border-box;
  font-family: 'Avenir Next Lt Pro';
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-loader {
  background-image: url('https://images.ctfassets.net/ff8p9jfqoa7x/7mYr0s4NWG6OL8m1Qv1OJK/020f67ee6c825e9829b4dfd45a6abbe0/app-loader.gif');
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: white;
}

.page-wrapper {
  padding-bottom: 50px;
  padding-top: 60px;

  &.login,
  &.accountUnlock,
  &.activationEmail,
  &.forgotPassword,
  &.passwordReset,
  &.loginSupport {
    padding-top: 0;
  }
}

input.credentials {
  border: none;
  background: #efefef;
  border-radius: 10px;
  height: 57px;
  width: 100%;
  outline: none;
  padding: 0 15px;
  margin-bottom: 10px;
  font-size: $inputSize;

  &::placeholder {
    color: #d8d8d8;
    font-size: $inputSize;
  }
}

.cancel-button {
  text-align: center;
  color: $lightGreen;
  font-size: 12px;
  text-decoration: underline;
  display: block;
}

button.submit-credentials {
  &,
  &.ant-btn {
    background-color: $lightGreen;
    outline: none;
    width: 100%;
    border: none;
    border-radius: 30px;
    height: 60px;
    color: $darkFontColor;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 600;
    cursor: pointer;
  }
}

.error-message {
  @include credentials-error-message;
  width: 100%;
  margin-top: 0;
  border-radius: 0;
  border: 2px solid #a22323;
  padding: 20px;
}

.underlined {
  text-decoration: underline;
}

.ant-row,
.row {
  margin-top: 10px;
}

.ant-form.ant-form-horizontal {
  width: 100%;
}

.row .ant-row {
  margin-top: 0;
  margin-bottom: 0;
}

input {
  outline: none;
}

ul.check-list {
  padding-left: 0;
  list-style: none;

  li {
    &:before {
      content: ' ';
      width: 18px;
      height: 16px;
      display: inline-block;
      margin-right: 10px;
      position: relative;
      top: 4px;
      background-image: url('https://images.ctfassets.net/ff8p9jfqoa7x/3qpdtj8laSpsOvW44on4mw/f340aac97dd05279468e85b626c19b86/check-icon.png');
      background-repeat: no-repeat;
    }
    font-size: 14px;
    line-height: 20px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
#root .ant-input-affix-wrapper > input.ant-input:focus {
}

input:-webkit-autofill::first-line {
  font-size: $inputSize;
}

.login-container .ant-form-item-control-input-content {
  .ant-input-affix-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    border-radius: 13px;
    padding-left: 15px;
  }

  .anticon {
    vertical-align: middle;
    line-height: 60px;
    height: 56px;
  }

  input[type='text'],
  input[type='password'] {
    flex: 1;
    padding-left: 10px;
    text-size-adjust: none;
  }
}

input[type='text'],
input[type='email'],
input[type='password'],
.ant-picker {
  border-radius: 4px;
  padding: 23px 15px 18px;
  font-size: $inputSize;
  width: 100%;
  height: 57px;

  &.invalid {
    color: #aeaeae;
    background: #ffd7d7 !important;
  }

  &.disabled,
  &[disabled] {
    border: 1px solid #979797;
    background: white;
    color: #aeaeae;
    padding: 22px 14px 17px;
    pointer-events: none;
  }
}

.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
.ant-form-item-has-error .ant-input-affix-wrapper input,
#root .ant-form-item-has-error .ant-input-affix-wrapper > input.ant-input:focus {
  box-shadow: 0 0 0 30px #ffd7d7 inset !important;
  background: #ffd7d7;
}

.ant-form-item-has-error .ant-input-affix-wrapper {
  &,
  & input {
    background: #ffd7d7;
  }
}

.ant-form-item-explain.ant-form-item-explain-error {
  color: red;
  font-size: 14px;
  width: 100%;
}

span.half.field-name {
  line-height: 57px;
}

.ant-row.ant-form-item {
  width: 100%;
}

.ant-input {
  border-radius: 4px;
}

.account-management-page a {
  color: $lightGreen;
}

$windowWidth: 375px;
$userAccountPadding: 15px;

footer {
  display: flex;
  position: fixed;
  bottom: 0;
  padding: 30px 30px 22px 30px;
  width: 100%;
  justify-content: center;
  border-top: 1px solid #979797;
  background: white;
  gap: 70px;
  left: 0;

  a {
    color: $navLinkFontColor;
    position: relative;
    font-size: 11px;

    &:before {
      content: '';
      width: 100%;
      height: 22px;
      background-repeat: no-repeat;
      background-position-y: center;
      background-position-x: center;
      position: absolute;
      bottom: 100%;
    }

    &#overview:before {
      background-image: url('https://images.ctfassets.net/ff8p9jfqoa7x/37XoQ0UbHCCqOW2blkJxOa/c0fe63969533468ea39a27b9b003efbc/overview.svg');
    }

    &#treatments:before {
      background-image: url('https://images.ctfassets.net/ff8p9jfqoa7x/2kXIhcKgk7g15OyfzxivOd/d13b4017cb9781ba41fa8c1fec0b086d/treatments.svg');
    }

    &#orders:before {
      background-image: url('https://images.ctfassets.net/ff8p9jfqoa7x/1XHJzX7zK1bMlxh3iXDgWj/e54a745806e141107d08336c2a14c1b0/orders.svg');
    }

    &:hover,
    &.active {
      color: $navActiveLinkFontColor;
    }

    &#overview:hover:before,
    &#overview.active:before {
      background-image: url('https://images.ctfassets.net/ff8p9jfqoa7x/67uQVDbqZb02VWLi8faoQ1/09015ce40b9c27d239eb13624a1573dd/overview-active.svg');
    }

    &#treatments:hover:before,
    &#treatments.active:before {
      background-image: url('https://images.ctfassets.net/ff8p9jfqoa7x/lyk8fgNUSIEfb6z6wCzHi/5ba4c9410ad41b0bf34578a3c2f1a9f4/treatments-active.svg');
    }

    &#orders:hover:before,
    &#orders.active:before {
      background-image: url('https://images.ctfassets.net/ff8p9jfqoa7x/6Emo0yuZTMkOo9DYIdQw5W/9c44f646b0f99a4fd698fc793f8324e4/orders-active.svg');
    }
  }
}

@media screen and (min-width: 1024px) {
  footer {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .page-wrapper {
    padding-bottom: 100px;
  }
}

.ant-row.ant-form-item {
  display: block;
}

.link-btn.disabled {
  opacity: 0.5;
}
