@import '../../scss/index.scss';

.wrapper-status {
  justify-self: end;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.2;
  text-align: center;
  font-weight: bold;
  padding: 4px 8px;
  min-width: 58px;
  max-width: fit-content;
  height: fit-content;

  &.open,
  &.pending,
  &.locked,
  &.accepted,
  &.prescribed,
  &.versendet,
  &.order_dispatched,
  &.with_failed_retailer_request,
  &.closed_with_failed_retailer_request,
  &.should_fulfill_next_order,
  &.closed_open,
  &.closed_ordered,
  &.delayed, // Note: this is not an actual status of the treatment
  &.prescriped {
    color: $processingFontColor;
    background-color: $processingColor;
  }

  &.ordered,
  &.zugestellt,
  &.order_arrived,
  &.in_progress {
    background-color: $secondaryLightGreen;
    color: $lightGreen;
  }

  &.declined,
  &.decline,
  &.cancelled,
  &.closed,
  &.cancelled_manually,
  &.closed_cancelled_manually,
  &.refunded,
  &.unsubscribed {
    color: $redFontColor;
    background-color: $overdueColor;
  }
}
